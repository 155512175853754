import { chunk } from 'lodash';
import {
  CSSProperties,
  HTMLProps,
  PropsWithoutRef,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';

import { liClass, pageClass, rootWrapperClass, scrollContainerClass } from './index.css';

type RootProps = PropsWithoutRef<HTMLProps<HTMLDivElement>> & {
  /** The number of columns on each page */
  columns?: number;
  /** The number of rows on each page */
  rows?: number;
  /** The current page */
  page?: number;
  /** Component will collapse to a horizontally scrollable component without pagination if set to true */
  isHorizontalScroll?: boolean;
};

const ConfigContext = createContext<{ columns: number; rows: number; page: number } | null>(null);

function getPadding(side: 'left' | 'right', elem: HTMLDivElement | undefined, fallback: string) {
  if (!elem) return fallback;
  const padding = side === 'left' ? elem.style.paddingLeft : elem.style.paddingRight;

  if (padding === '') {
    // we want to return 60px as an arbitary "imaginary" padding to ensure that effects like shadows
    // can still be displayed properly using overflow: clip
    return '60px';
  }
  return padding;
}

const Root = forwardRef<HTMLDivElement, RootProps>(function Root(
  {
    children,
    style,
    className,
    columns = 6,
    rows = 1,
    page = 1,
    isHorizontalScroll = false,
    ...props
  },
  ref,
) {
  const divRef = useRef<HTMLDivElement>();

  const cssProps = {
    '--columns': columns,
    '--rows': rows,
    '--page': page,
    // we add a generous fallback when the ref has not been attached yet to ensure the next
    // pages are not visible
    '--padding-left': getPadding('left', divRef.current, '250px'),
    '--padding-right': getPadding('right', divRef.current, '250px'),
  } as CSSProperties;

  return (
    <div
      /* There is a quirk in React Rehydration (maybe only in ISR) that sometimes causes non-standard attributes (like data-horizontal-scroll)
       * to be updated. Setting this key will force React to re-render the component on prop changes
       */
      key={`${columns}-${rows}-${isHorizontalScroll}`}
      ref={mergeRefs([divRef, ref])}
      className={`${rootWrapperClass} ${className ? className : ''}`}
      style={{ ...style, ...cssProps }}
      data-horizontal-scroll={isHorizontalScroll}
      {...props}
    >
      <ConfigContext.Provider value={{ columns, rows, page }}>{children}</ConfigContext.Provider>
    </div>
  );
});

type ListProps = Omit<PropsWithoutRef<HTMLProps<HTMLUListElement>>, 'children'> &
  (
    | {
        items: { id: string; node: ReactNode }[];
        pages?: undefined;
      }
    | {
        pages: ((isActive: boolean) => ReactNode)[];
        items?: undefined;
      }
  );

const List = forwardRef<HTMLUListElement, ListProps>(function List(
  { items, className, style, pages, ...props },
  ref,
) {
  const ulRef = useRef<HTMLUListElement>(null);

  const [maxItemWidth, setMaxItemWidth] = useState<string>('auto');

  const config = useContext(ConfigContext);

  const pagesToRender = pages ?? chunk(items, (config?.columns ?? 5) * (config?.rows ?? 1)); // split into pages

  useEffect(() => {
    // when we are in the horizontally scrolling mode, we need to get the width of
    // the widest item to ensure that all items will be sized equally

    if (!ulRef.current) {
      setMaxItemWidth('auto');
      return;
    }

    let maxWidth = 0;
    for (const pageWrapper of ulRef.current.children) {
      const page = pageWrapper.children[0];
      if (!page) continue;
      for (const item of page.children) {
        maxWidth = Math.max(maxWidth, item.clientWidth);
      }
    }

    setMaxItemWidth(`${maxWidth}px`);
  }, [ulRef]);

  const childrenVar = {
    '--max-item-width': maxItemWidth,
  } as CSSProperties;
  return (
    <ul
      style={{ ...style, ...childrenVar }}
      className={`${scrollContainerClass} ${className ? className : ''}`}
      {...props}
      ref={mergeRefs([ref, ulRef])}
    >
      {pagesToRender.map((page, idx) => (
        <li key={idx}>
          {pages ? (
            (page as (active: boolean) => ReactNode)((config?.page || 1) - 1 === idx)
          ) : (
            <ul className={pageClass}>
              {(page as { id: string; node: ReactNode }[]).map(({ id, node }) => (
                <li key={id} data-active={(config?.page || 1) - 1 === idx} className={liClass}>
                  {node}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
});

export { Root, List };
