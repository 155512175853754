import Image from 'next/image';

import ExternalLink from '@/ui/Link/ExternalLink';

import {
  postPreviewClass,
  postPreviewContentContainerClass,
  postPreviewImageClass,
  postPreviewImageContainerClass,
  postPreviewSummaryClass,
  postPreviewTitleClass,
} from './index.css';
import type { BlogPost } from './types';

type PostPreviewProps = Omit<BlogPost, 'id'>;

/**
 * Component for displaying a blog post preview.
 */
export const PostPreview = ({ image, summary, title, url }: PostPreviewProps) => (
  <ExternalLink href={url} className={postPreviewClass}>
    <span className={postPreviewImageContainerClass}>
      <Image
        alt={image.alt ?? title}
        className={postPreviewImageClass}
        src={image.src}
        fill={true}
      />
    </span>

    <span className={postPreviewContentContainerClass}>
      <h2 className={postPreviewTitleClass}>{title}</h2>

      <p className={postPreviewSummaryClass}>{summary}</p>
    </span>
  </ExternalLink>
);
