import Image from 'next/image';

import { SUPPORTED_CHAIN_IDS } from '@/constants/chain';

import { ChevronDownSmallIcon } from '@/ui/Icons';
import ExternalLink from '@/ui/Link/ExternalLink';

import { routes } from '@/utils/routes';

import {
  modesContainerClass,
  modeClass,
  modeTextContainerClass,
  modeTextClass,
  modeTextHighlightClass,
  learnMoreButtonClass,
  chevronIconClass,
} from '../index.css';

export function MarketingFeaturesSection({
  swapText = `Zero fee trades on 9+ million tokens across ${SUPPORTED_CHAIN_IDS.length} chains.`,
  limitOrdersText = 'Get the price you want. No surprises.',
  crossChainText = 'Trade seamlessly across chains.',
}) {
  return (
    <div className={modesContainerClass}>
      <div className={modeClass}>
        <div className={modeTextContainerClass}>
          <div className={modeTextClass}>
            <span className={modeTextHighlightClass}>Swap.</span> {swapText}
          </div>
          <ExternalLink href={routes.SWAP_TOKENS}>
            <div className={learnMoreButtonClass}>
              Learn more <ChevronDownSmallIcon className={chevronIconClass} />
            </div>
          </ExternalLink>
        </div>
        <Image src="/images/new-landing/swap-mode.webp" alt="swap" width={118} height={118} />
      </div>

      <div className={modeClass}>
        <div className={modeTextContainerClass}>
          <div className={modeTextClass}>
            <span className={modeTextHighlightClass}>Limit orders.</span> {limitOrdersText}
          </div>
          <ExternalLink href={routes.LIMIT_ORDERS}>
            <div className={learnMoreButtonClass}>
              Learn more <ChevronDownSmallIcon className={chevronIconClass} />
            </div>
          </ExternalLink>
        </div>
        <Image
          src="/images/new-landing/limit-mode.webp"
          alt="limit orders"
          width={118}
          height={118}
        />
      </div>

      <div className={modeClass}>
        <div className={modeTextContainerClass}>
          <div className={modeTextClass}>
            <span className={modeTextHighlightClass}>Cross chain swaps.</span> {crossChainText}
          </div>
          <ExternalLink href={routes.CROSS_CHAIN_SWAPS}>
            <div className={learnMoreButtonClass}>
              Learn more <ChevronDownSmallIcon className={chevronIconClass} />
            </div>
          </ExternalLink>
        </div>
        <Image
          src="/images/new-landing/cross-chain-mode.webp"
          alt="cross chain swaps"
          width={118}
          height={118}
        />
      </div>
    </div>
  );
}
