import Link from 'next/link';

import { SUPPORTED_CHAIN_IDS } from '@/constants/chain';

import { ChevronDownSmallIcon } from '@/ui/Icons';

import { routes } from '@/utils/routes';

import {
  liquidityContainerClass,
  liquidityImageContainerClass,
  liquidityTextContainerClass,
  liquidityTitleClass,
  liquidityDescriptionClass,
  learnMoreButtonClass,
  chevronIconClass,
} from '../index.css';

export function MarketingLiquiditySection({
  bodyText = `Zero fee trades on 9+ million tokens across ${SUPPORTED_CHAIN_IDS.length} chains. Get the best prices by tapping
    into 130+ liquidity sources.`,
}) {
  return (
    <div className={liquidityContainerClass}>
      <div className={liquidityImageContainerClass}>
        <div className={liquidityTextContainerClass}>
          <div className={liquidityTitleClass}>More than 130+ liquidity sources</div>
          <div className={liquidityDescriptionClass}>{bodyText}</div>
          <Link target="_blank" referrerPolicy="no-referrer" href={routes.LIQUIDITY_SOURCES}>
            <div className={learnMoreButtonClass}>
              Learn more <ChevronDownSmallIcon className={chevronIconClass} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
